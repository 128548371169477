<template>
	<div id="ag-grid-demo">
		<vx-card class="mb-base">
			<div class="vx-row">
				<div class="vx-col sm:w-1/3 w-full mb-2">
					<datepicker class="w-full" placeholder="Start Date" v-model="search['startDate']"></datepicker>
				</div>
				<div class="vx-col sm:w-1/3 w-full mb-2">
					<datepicker class="w-full" placeholder="End Date" v-model="search['endDate']"></datepicker>
				</div>
				<div class="vx-col sm:w-1/3 w-full mb-2">
					<v-select class="w-full" placeholder="TLD" v-model="search['tld']" :options="formTLD"></v-select>
				</div>
			</div>
			<div class="vx-row">
				<div class="vx-col w-full mt-4">
					<vs-button ref="loadableButtonDomainSearch" id="button-with-loading-domain-search" class="mb-2 mr-4" @click="submitSearch()" :disabled="search['startDate'] == '' || search['endDate'] == ''">Search</vs-button>
					<vs-button class="mb-2" color="danger" type="border" @click="resetSearch()">Reset</vs-button>
				</div>
			</div>
		</vx-card>

		<vx-card :title="`Report for ${tableDataFor ? '.'+tableDataFor : 'all TLDs'}`">
			<ag-grid-vue
				:gridOptions="gridOptions"
				class="ag-theme-material w-100 mb-4 ag-grid-table"
				:columnDefs="columnDefs"
				:defaultColDef="defaultColDef"
				:rowData="tableData"
				rowSelection="multiple"
				:enableRangeSelection="true"
				:suppressCopyRowsToClipboard="true"
				colResizeDefault="shift"
				:suppressDragLeaveHidesColumns="true"
				:animateRows="false"
				:floatingFilter="true"
				:pagination="true"
				:paginationPageSize="paginationPageSize"
				:suppressPaginationPanel="true"
				:suppressRowClickSelection="true"
				:rowClassRules="rowClassRules"
				:statusBar="statusBar"
				:sideBar="sideBar"
				:rowHeight="38"
				@filter-changed="onFilterChanged"
				@column-resized="onColumnResized">
			</ag-grid-vue>
			<div class="vx-row">
				<div class="vx-col w-full">
					<vs-pagination
					:total="totalPages"
					:max="maxPageNumbers"
					v-model="currentPage" />
				</div>
			</div>
		</vx-card>
	</div>
</template>

<script>
	import { AgGridVue } from "ag-grid-vue"
	import "ag-grid-enterprise";
    import Datepicker from 'vuejs-datepicker';
    import vSelect from 'vue-select'

	import '@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss'

	export default {
		components: {
			AgGridVue,
            Datepicker,
            'v-select': vSelect,
		},
		data() {
			return {
				searchQuery: '',
				gridOptions: {},
				maxPageNumbers: 7,
				gridApi: null,
				defaultColDef: {
					sortable: true,
					resizable: true,
					suppressMenu: true,
					suppressMovable: false,
					filter: false
				},
				rowClassRules: null,
				statusBar: null,
				sideBar: null,
				columnDefs: [
				{
					headerName: 'Rank',
					field: 'rank',
					filter: "agTextColumnFilter",
					minWidth: 100,
				},
				{
					headerName: 'Registrar',
					field: 'registrar',
					filter: "agTextColumnFilter",
					minWidth: 275,
				},
				{
					headerName: 'Registrations',
					field: 'registrations',
					filter: "agTextColumnFilter",
					minWidth: 150,
				},
				{
					headerName: 'Suspensions',
					field: 'suspensions',
					filter: "agTextColumnFilter",
					minWidth: 150,
				},
				{
					headerName: 'Reactive',
					field: 'reactive',
					filter: "agTextColumnFilter",
					minWidth: 150,
				},
				{
					headerName: 'Proactive',
					field: 'proactive',
					filter: "agTextColumnFilter",
					minWidth: 150,
				},
				{
					headerName: 'Unsuspensions',
					field: 'unsuspensions',
					filter: "agTextColumnFilter",
					minWidth: 150,
				},
				{
					headerName: 'Abuse Rate',
					field: 'abuse_rate',
					filter: "agTextColumnFilter",
					minWidth: 150,
				},
				{
					headerName: 'Abuse Rate (reactive)',
					field: 'abuse_rate_reactive',
					filter: "agTextColumnFilter",
					minWidth: 150,
				},
				{
					headerName: 'Unsuspension Rate',
					field: 'unsuspension_rate',
					filter: "agTextColumnFilter",
					minWidth: 150,
				},
				],
				tableData: [],
				tableDataFor: null,
				initData: {
                    tld: []
				},
				search: {
					startDate: '',
					endDate: '',
					tld: null
				}
			}
		},
		watch: {
			windowWidth(val) {
				// Toggle colummn movable
				this.toggleColumnMovable(val);
				// Toggle colummn pinning
				this.toggleColumnPinned(val);
			},
		},
		computed: {
			windowWidth: function(){
				return this.$store.state.windowWidth;
			},
			paginationPageSize() {
				if(this.gridApi) {
					return this.gridApi.paginationGetPageSize()
				}
				else {
					return 500
				}
			},
			totalPages() {
				if(this.gridApi) {
					return this.gridApi.paginationGetTotalPages()
				}
				else {
					return 0
				}
			},
			currentPage: {
				get() {
					if(this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
						else return 1
					},
				set(val) {
					this.gridApi.paginationGoToPage(val - 1);
				}
			},
			formTLD: function(){
				let tldArray = this.initData['tld'].map(x => {
					return {
						label: x['tld'],
						value: x['id']
					}
				});
				
				return tldArray;
			},
		},
		methods: {
			showButtonLoading(){
				this.$vs.loading({
					background: 'primary',
					color: '#FFFFFF',
					container: '#button-with-loading-domain-search',
					scale: 0.45
				});
			},
			hideButtonLoading(){
				this.$vs.loading.close("#button-with-loading-domain-search > .con-vs-loading");
			},
			onFilterChanged() {
				// Set Listing Filtered Count
				this.$store.dispatch("setListingFilteredCount", this.gridApi.getDisplayedRowCount());
			},
			resetSearch() {
				this.search['startDate'] = '';
				this.search['endDate'] = '';
				this.search['tld']['value'] = '';
			},
			submitSearch() {
				let self = this;

				// Show loader
				this.showButtonLoading();
				// Search Axios call
				this.$axiosSecure.get('/registrar-abuse-report', {
					params: {
						startDate: self.$moment.utc(self.search['startDate']).local().format('DD-MM-YYYY'),
						endDate: self.$moment.utc(self.search['endDate']).local().format('DD-MM-YYYY'),
						tld: self.search['tld'] ? self.search['tld']['label'] : ''
					}
				})
				.then((response) => {
					this.tableData = response.data;
					this.tableDataFor = self.search['tld'] ? self.search['tld']['label'] : null

					// Set Listing Count
					this.$store.dispatch("setListingTotalCount", response.data.length);
					// Set Listing Filtered Count
					this.$store.dispatch("setListingFilteredCount", response.data.length);

					// Hide loader
					this.hideButtonLoading();
				})
				.catch((error) => {
					// Error notification
					self.$vs.notify({
						color: 'danger',
						title: 'Something went wrong',
						text: 'Please contact the server admin'
					});

					console.log(error);
					
					// Clear Listing Count
					this.$store.dispatch("clearListingCount");

					// Hide loader
					this.hideButtonLoading();

					// Clear TLD
					this.tableDataFor = null
				});
			},
			toggleColumnMovable(val) {
				if(val <= 576) {
					this.defaultColDef['suppressMovable'] = true;
				}
				else {
					this.defaultColDef['suppressMovable'] = false;
				}
			},
			toggleColumnPinned(val) {
				if(val <= 576) {
					this.maxPageNumbers = 4;
					this.gridOptions.columnApi.setColumnPinned('domain', null);
				}
				else {
					this.gridOptions.columnApi.setColumnPinned('domain', 'left')
				}
			},
			onColumnResized() {
				this.gridApi.resetRowHeights();
			},
			sizeColumnsToFit() {
				this.gridApi.sizeColumnsToFit();
			},
		},
		beforeMount() {
			// Reset movable columns on mobile
			this.toggleColumnMovable(this.windowWidth);

			// Set Grid sidebar
			this.sideBar = {
				toolPanels: [
				{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel",
					toolPanelParams: {
						suppressRowGroups: true,
						suppressValues: true,
						suppressPivots: true,
						suppressPivotMode: true,
						suppressSideButtons: true,
						suppressColumnSelectAll: true,
						suppressColumnExpandAll: true
					}
				}
				],
			};
		},
		mounted() {
			let self = this;

			this.gridApi = this.gridOptions.api;

			// Resize to fit columns
			this.sizeColumnsToFit();

			// Reset pinned columns on mobile
			this.toggleColumnPinned(this.windowWidth);

			// Page Refresh Event
			this.$root.$on('refreshPage', () => {
				// Reset filters
				this.gridApi.setFilterModel(null);
				// Reset selection
				this.gridApi.deselectAll();
				// Reset sort
				this.gridApi.setSortModel(null);
			});

			// Page Download Event
			this.$root.$on('downloadPage', () => {
				// AgGrid CSV Export
				this.gridApi.exportDataAsCsv()
			});
			
			// Get TLD JSON
			this.$axiosSecure.get('/get-tlds')
			.then((response) => {
				self.initData['tld'] = response.data;
			})
			.catch((error) => {
				// Error notification
				self.$vs.notify({
					color: 'danger',
					title: 'Something went wrong',
					text: 'Please contact the server admin'
				});

				console.log(error);
			});
		},
		destroyed() {
			// Clear Listing Count
			this.$store.dispatch("clearListingCount");
			
			// Unbind Button Event Listeners
			this.$root.$off('refreshPage');
			this.$root.$off('downloadPage');
		}
	}

</script>